<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body ">
  @if (isDelete) {
    <img src="/assets/imgs/qyd/confirm-delete.svg" alt="" srcset="">
  }
  @if (!isDelete && !attension) {
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.458 7.02397L3.88261 88.2542C0.523779 94.0617 4.71472 101.327 11.4235 101.327H92.5744C99.2832 101.327 103.474 94.0617 100.116 88.2542L59.5402 7.02397C56.1856 1.22378 47.8126 1.22378 44.458 7.02397Z"
        stroke="#FF6F59" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M56.3648 62.2327L58.6896 35.9732C59.0046 32.0545 55.9053 28.7025 51.9739 28.71C48.0328 28.7174 44.943 32.0972 45.2881 36.0232L47.8281 62.2645C48.0236 64.4884 49.8943 66.1894 52.1269 66.1729C54.3472 66.1564 56.187 64.4461 56.3648 62.2327Z"
        fill="#FF6F59" stroke="#FF6F59" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M51.9873 89.4043C55.1904 89.4043 57.7871 86.8076 57.7871 83.6045C57.7871 80.4013 55.1904 77.8047 51.9873 77.8047C48.7842 77.8047 46.1875 80.4013 46.1875 83.6045C46.1875 86.8076 48.7842 89.4043 51.9873 89.4043Z"
        fill="#FF6F59" stroke="#FF6F59" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  }
  @if (attension) {
    <svg width="132" height="115" viewBox="0 0 132 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.87669 103.018C3.81583 108.351 7.66596 115 13.8152 115H118.185C124.334 115 128.184 108.351 125.123 103.018L72.9385 12.0898C69.8639 6.73262 62.1361 6.73262 59.0615 12.0898L6.87669 103.018ZM72 90.7895C72 94.1032 69.3137 96.8421 66 96.8421C62.6863 96.8421 60 94.1032 60 90.7895C60 87.4758 62.6863 84.7368 66 84.7368C69.3137 84.7368 72 87.4758 72 90.7895ZM72 66.6316C72 69.9453 69.3137 72.6316 66 72.6316C62.6863 72.6316 60 69.9453 60 66.6316V54.4211C60 51.1073 62.6863 48.4211 66 48.4211C69.3137 48.4211 72 51.1073 72 54.4211V66.6316Z"
        fill="#FFB800" />
    </svg>
  }


  <h4 class="modal-title">{{ title }}</h4>
  <p>{{ message }}</p>
  <p>{{ data }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="genral-button" (click)="accept()" [innerHTML]="btnOkText"></button>
  <button type="button" class="general-outline-button" (click)="decline()">{{ btnCancelText }}</button>
</div>