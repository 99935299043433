import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { WebApiService } from './app/services/webApi.service';
import { AuthService } from './app/modules/auth/services/auth.service';
import { ConfirmationDialogService } from './app/modules/SharedComponent/SharedComponent/confirmation-dialog/confirmation-dialog.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { initializeApp } from 'firebase/app';
import { AuthInterceptor } from './app/modules/auth/interceptors/auth.interceptor';

// function appInitializer(authService: AuthService) {
//   return () => {
//     return new Promise((resolve) => {
//       //@ts-ignore
//       authService.getUserByToken().subscribe().add(resolve);
//     });
//   };
// }



initializeApp(environment.firebase);
function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, TranslateModule.forRoot(), ClipboardModule, InlineSVGModule, FormsModule, ReactiveFormsModule, AppRoutingModule, InlineSVGModule.forRoot(), NgbModule, NgMultiSelectDropDownModule.forRoot(), ToastrModule.forRoot({ timeOut: 2000, preventDuplicates: true })),
    WebApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    ConfirmationDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true, // Allow multiple interceptors
    },

    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
})
  .catch((err) => console.error(err));
